<template>
  <v-container class="fade-transition">
    <!--END summary stats-->
    <v-row>
      <v-col cols="12">
        <v-card tile class="mt-3" elevation="0">
          <v-divider />
          <v-container
            style="min-height: 50vh"
            class="d-flex flex-row justify-center"
          >
            <v-card min-width="100%" flat>
              <!--BEGIN top bar-->
              <v-row class="top-bar">
                <v-col cols="6" class="text-center">
                  <v-toolbar flat color="transparent">
                    <h3 class="request-header">Pending Approvals</h3>
                  </v-toolbar>
                </v-col>
                <v-col cols="6" class="text-center">
                  <v-toolbar flat color="transparent">
                    <v-text-field
                      v-model="search"
                      append-icon="mdi-magnify"
                      label="Search"
                      single-line
                      hide-details
                    ></v-text-field>
                  </v-toolbar>
                </v-col>
              </v-row>
              <!--END top bar header-->

              <!--BEGIN the table of requests-->
              <v-card-text>
                <v-data-table
                  :headers="headers"
                  :items="pendingImprests"
                  :search="search"
                  :items-per-page="5"
                  fixed-header
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :custom-filter="customFilter"
                >
                  <!--BEGIN date slot-->
                  <template v-slot:item.employeeNo="{ item }">
                    <span>{{ getEmployee(item.employeeNo) }}</span>
                  </template>
                  <!--eslint-disable-next-line -->
                  <template v-slot:item.postingDate="{ item }">
                    <span>{{ item.postingDate | formatDate }}</span>
                  </template>
                  <!--END date slot-->
                  <!--BEGIN amount slot-->
                  <!--eslint-disable-next-line -->
                  <template v-slot:item.amountToPayHeader="{ item }">
                    <span>{{ item.amountToPayHeader | currencyFormat }}</span>
                  </template>
                  <!--END amount slot-->
                  <!--BEGIN start date-->
                  <!--eslint-disable-next-line -->
                  <template v-slot:item.startDate="{ item }">
                    <span>{{ item.startDate | formatDate }}</span>
                  </template>
                  <!--END start date-->
                  <!--BEGIN end date-->
                  <!--eslint-disable-next-line -->
                  <template v-slot:item.endDate="{ item }">
                    <span>{{ item.endDate | formatDate }}</span>
                  </template>
                  <!--END end date-->
                  <!--BEGIN end date-->
                  <!--eslint-disable-next-line -->
                  <template v-slot:item.description="{ item }">
                    <span>{{ readMore(item.description, 15) }}</span>
                  </template>
                  <!--END end date-->
                  <!---BEGIN actions slot-->
                  <!--eslint-disable-next-line -->
                  <template v-slot:item.action="{ item }">
                    <div class="d-flex justify-start">
                      <v-chip
                        small
                        color="success"
                        label
                        :to="{
                          name: 'ImprestApprove',
                          params: { approveCode: item.no },
                        }"
                        class="mx-1"
                      >
                        <v-icon small left color="white">mdi-eye</v-icon> view
                      </v-chip>
                    </div>
                  </template>
                  <!--END actions slot-->
                </v-data-table>
              </v-card-text>
              <!-- END the table of requests-->
            </v-card>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import helper from "@kinetics254/cassandra-base/utilities/helpers/helper";
import ImprestMixin from "../ImprestMixin.js";
import moment from "moment/moment";
export default {
  name: "ImprestMyApprovalsList",
  mixins: [ImprestMixin],
  data: function () {
    return {
      sortBy: "no",
      sortDesc: true,
      loaded: false,
      search: "",
      tab: null,
      headers: [
        {
          text: "#no",
          align: "center",
          sortable: false,
          value: "no",
        },
        { text: "Employee", align: "start", value: "employeeNo" },
        { text: "Date", align: "center", value: "postingDate" },
        { text: "Amount (KES)", align: "center", value: "amountToPayHeader" },
        { text: "Description", value: "description" },
        { text: "Start Date", align: "center", value: "startDate" },
        { text: "End Date", align: "center", value: "endDate" },
        { text: "Action", align: "start", value: "action", sortable: false },
      ],
    };
  },
  beforeRouteEnter(to, from, next) {
    next((v) => {
      v.$store.dispatch("imprest/setBreadCrumbs", to);
      v.$store.dispatch("imprest/getPendingApprovals");
    });
  },
  computed: {
    pendingImprests() {
      return this.$store.getters["imprest/pendingImprests"];
    },
    countRequests: function () {
      if (!this.pendingImprests) return 0;
      return this.pendingImprests.length;
    },
    employees() {
      return this.$store.getters["Setup/setupGetter"]("leaveEmployees");
    },
  },
  methods: {
    /**
     * Call the imprest/getImprests action to remove an item
     */
    deleteImprest: function (data) {
      this.$confirm.show({
        title: ` imprest ID ${data.no}`,
        text: "Are you sure you want to remove this imprest request?",
        onConfirm: () => {
          this.$store.dispatch("imprest/deleteImprest", data);
        },
        onCancel: () => {
          this.$store.dispatch("imprest/getImprests");
        },
      });
    },
    /**
     * Add read more to long texts
     * @param text
     * @param length
     * @returns {string|*}
     */
    readMore(text, length) {
      return helper.addReadMore(text, length);
    },
    // enable filter by month name
    customFilter(value, search, item) {
      var months = [
        "jan",
        "feb",
        "mar",
        "apr",
        "may",
        "jun",
        "jul",
        "aug",
        "sep",
        "oct",
        "nov",
        "dec",
      ];
      var month = search.toLowerCase().substr(0, 3);
      if (months.includes(month)) {
        var monthNumber = moment().month(search.toString()).format("MM");
      }
      return (
        value != null &&
        search != null &&
        value.toString().indexOf(monthNumber || search) !== -1
      );
    },
    getEmployee(no) {
      const employee = this.employees.filter((el) => el.number === no);
      return employee.length !== 0
        ? employee[0].first_name + " " + employee[0].last_name
        : "";
    },
  },
};
</script>
<style scoped>
* {
  font-family: Poppins, sans-serif !important;
}
.top-bar {
  background-color: #e8eaf6 !important;
  cursor: pointer;
  border-bottom: 1px solid #e8eaf6;
}
.statistic-cards-hover {
  box-shadow: 0 40px 60px -20px rgba(12, 5, 62, 0.15);
  z-index: 100;
  cursor: pointer;
  font-size: 14px;
  background-color: #e8eaf6;
}
.request-header {
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 175.6%;
  text-align: justify;
  color: #1c4271;
  text-transform: uppercase;
}
</style>
